import { storageUrl } from "../../../../../../../constants/apiRequests";
import Icon from "../../../../../../icons/Icon";
import DropDownMenu from "../../../../../menu";
import ReactSvg from "../../../../../ReactSvg";



type Props = {
    selectAction : (node:any) => void;
    selectedVolum:{value:string},
    volumeList: any
}

export default function VolumesDropdown({ selectedVolum, selectAction, volumeList}:Props) {


  function mapTreemenu(tree:any, onselect:any) {
    return tree.map((node:any) => {
        
    if(!node.node){
        node.children = undefined
    }
      if (node.children && node.children.length > 0) mapTreemenu(node.children,onselect)
      if(node.iconUrl){
        node.iconUrl = `${node.iconUrl}`
        node.icon = 
          <div className={`relative icon-sm`}>
              <ReactSvg src={`${node.iconUrl}`} className='w-full h-full' />
          </div>
          
      }
    
  
      node.key = node.id
      node.value = node.id
        
    node.label = node.title
        
    if(node.selectable !== false){
        node.onClick = () => onselect(node)

    }
     
    
      return node
    })
  }
  return (

        <DropDownMenu
          selectedKeys={[selectedVolum.value]}
          items={[...mapTreemenu(JSON.parse(JSON.stringify(volumeList)),selectAction)]}
        
        />
  )
}

