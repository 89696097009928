import { Dropdown } from 'antd'
import { useEffect, useState } from 'react'
import { filterProps, ResourcesActions } from '../../../../types';
import { useParams } from 'react-router';
import { getClusterTree } from '../../../../utils/requests';
import { ButtonLg } from '../../../../components/shared/Buttons/ButtonLg';
import VolumesDropdown from '../../../../components/shared/bars/TopBar/actionsBar/actions/resources/VolumesDropdown';



type Props={
    setSelectedVolume: (data: {value:string, icon:string, title:string}) => void,
    selectedVolume: {value:string, icon:string, title:string},
}
export default function Volumes({selectedVolume, setSelectedVolume}: Props) {
    const [loading, setloading] = useState(false)
    const [volumes, setvolumes] = useState([]);
    // const [filterDropdown, setfilterDropdown] = useState(false)
    const params = useParams()
    
    useEffect(() => {
        (async() => {
            setloading(true)
                console.log('ex')
                const data = await fetch(getClusterTree({id:params.id!})).then(res => res.json())
                if(data.data?.length >1){
                    setvolumes(data.data.slice(0,2))   
                }else{
                    setvolumes(data.data?.[0]?.children)
                }
            
            setloading(false)
        })()
    }, [params.id]) 

  return (
           
              
        <Dropdown 
        overlay={(!selectedVolume) ? <></> : <VolumesDropdown selectedVolum={selectedVolume} selectAction={(node:any) => setSelectedVolume &&setSelectedVolume({ value: node.id, icon: node.iconUrl , title:node.title})} volumeList={volumes}/>} trigger={['click']} disabled={!!(loading || params.group)} 
        // onVisibleChange={visible => setfilterDropdown(visible)}
        >
            {/* <Spin spinning={loading}> */}
            <button className='w-[150px]'>
                <ButtonLg
                    suffix={{
                        icon:{type:'icon', src: selectedVolume?.value ? 'XCircleIcon' : 'ChevronDownIcon'},
                        size: 'mini',
                        onClick:() => selectedVolume?.value ? setSelectedVolume &&setSelectedVolume({value:'', icon: '', title: ''}) : {}
                    }}
                    prefix={{
                        icon:selectedVolume?.value ? {type:'image', src: selectedVolume?.icon} : {type:'icon', src: 'LocalTree'},
                        size: 'sm'
                    }}
                    buttonProps={{
                        disabled:!!(loading || !selectedVolume || params.group)

                    }}
                ><>{selectedVolume?.value? selectedVolume.title :'Volume'}</></ButtonLg>

            </button>
            

        </Dropdown>
        
            

          
            

              


  )
}
