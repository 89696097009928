import ReactDOM, { createRoot } from 'react-dom/client';
import { createMemoryRouter, RouterProvider } from 'react-router-dom';
import Layout from './pages/Layout';
import 'antd/dist/antd.css';
import './styles/theme.css'
import './styles/global.css'

import { AppContextProvider } from './context/appContext';
import ResourcesLayout from './pages/widget/resources';
import AllPublicationsLayout from './pages/widget/resources/allPublications';
import PublicationDetails from './components/shared/details/Publications';
import VolumeDetails from './components/shared/details/volume';
import ResourcesContainerLayout from './pages/widget/resources/Layout';
import WidgetLayout from './pages/widget/Layout';





let container = document.createElement("div");
container.classList.add("teddra-widget");
const shadowRoot = container.attachShadow({ mode: "open" });


const domElement = document.getElementById("teddra-widget-container");
domElement!.after(container);





const linkElem = document.createElement('link');
linkElem.setAttribute('rel', 'stylesheet');
linkElem.setAttribute('href', 'https://widget-integrator.teddra.com/static/css/main.css');
// linkElem.setAttribute('href', 'C:\Users\saadi\Desktop\teddra\teddra-widget-integration/build/static/css/main.css');



const antCss = document.createElement('script')
antCss.setAttribute('src', 'https://cdn.tailwindcss.com');






shadowRoot.appendChild(linkElem);



shadowRoot.appendChild(antCss);


(window as any).teddraWidget = function(config:any) {
  const root = createRoot(shadowRoot);



  const widgetRoutes = [
    {
      path: "/",
      element: <AppContextProvider><Layout config={config}/></AppContextProvider>,
      children: [
       
        {
          path: '/widget',
          element: <WidgetLayout/>,
          children:[
            
            {
              element: <ResourcesContainerLayout/>,
              path: '/widget/main/:datacenterId/:coll/:id',
              children: [
                {
                  element: <ResourcesLayout/>,
                  path: '/widget/main/:datacenterId/:coll/:id',
                  children: [
                    {
                      element: <PublicationDetails/>,
                      path:'/widget/main/:datacenterId/:coll/:id/publications/:publicationId'
                    },
                    {
                      element: <VolumeDetails/>,
                      path:'/widget/main/:datacenterId/:coll/:id/volumes/:volumeId'
                    },
                  ]
                },
                {
                  element: <AllPublicationsLayout/>,
                  path: '/widget/main/:datacenterId/:coll/:id/all/publications',
                  children : [
                    {
                      path: '/widget/main/:datacenterId/:coll/:id/all/publications/:publicationId',
                      element: <PublicationDetails/>
                    }
                  ]
                },
                // {
                //   element: <AllVolumsLayout/>,
                //   path: '/widget/main/:datacenterId/:serverId/all/volumes',
                //   children : [
                //     {
                //       path: '/widget/main/:datacenterId/:serverId/all/volumes/:volumeId',
                //       element: <VolumeDetails/>
                //     }
                //   ]
                // }
              ]
            },
            {
              element: <ResourcesContainerLayout/>,
              path: '/widget/main/:datacenterId/:serverId/:type/:volume',
              children: [
                {
                  element: <ResourcesLayout/>,
                  path: '/widget/main/:datacenterId/:serverId/:type/:volume',
                  children: [
                    {
                      element: <PublicationDetails/>,
                      path:'/widget/main/:datacenterId/:serverId/:type/:volume/publications/:publicationId'
                    },
                    {
                      element: <VolumeDetails/>,
                      path:'/widget/main/:datacenterId/:serverId/:type/:volume/volumes/:volumeId'
                    },
                  ]
                },
                {
                  element: <AllPublicationsLayout/>,
                  path: '/widget/main/:datacenterId/:serverId/:type/:volume/all/publications',
                  children : [
                    {
                      path: '/widget/main/:datacenterId/:serverId/:type/:volume/all/publications/:publicationId',
                      element: <PublicationDetails/>
                    }
                  ]
                },
                // {
                //   element: <AllVolumsLayout/>,
                //   path: '/widget/main/:datacenterId/:serverId/:type/:volume/all/volumes',
                //   children : [
                //     {
                //       path: '/widget/main/:datacenterId/:serverId/:type/:volume/all/volumes/:volumeId',
                //       element: <VolumeDetails/>
                //     }
                //   ]
                // }
              ]
            }
          ]
        }
      ]
    }
  ]
  const routes = createMemoryRouter(widgetRoutes,{
    initialEntries: ["/"],
    initialIndex:1
  })
  
  root.render(<RouterProvider router={routes}/>);
  return this;
}




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
