import { Spin } from 'antd'
import React, { useEffect, useState } from 'react'

import ResourcesLayout from '../resourcesLayout'
import { Outlet } from 'react-router'
import { useAppContext } from '../../../../context/appContext'
import { Highlight, OwnerType, PartnerType, Publication } from '../../../../types'
import Icon from '../../../../components/icons/Icon'
import { SearchIcon } from '@heroicons/react/solid'

type Props = {
  sponsors: null | PartnerType[],
  publications: {document:Publication, highlight:Highlight}[] | undefined,
  webResources: {document:Publication, highlight:Highlight}[] | undefined,
  partnerPublications?:{document:Publication, highlight:Highlight}[] | undefined
  suffix:string,
  showApps?:boolean,
  allowSearch?:boolean,
  groupBy?:{
    webresources:boolean,
    paidPubs:boolean
  },
  owner?: OwnerType,
  all?:string,
  home?:boolean,
  layoutProps:{
      loading:boolean 
      id:string,
      collection:string
    },
    isLoaded:boolean,
    partnerId?:string
}
export default function LocationLayout({ layoutProps, sponsors, publications, suffix, showApps = false, allowSearch = true, groupBy, owner,all , home, webResources, isLoaded, partnerPublications, partnerId} : Props) {
    const { clearSearch, query, engine} = useAppContext()
    useEffect(() => {
        clearSearch()
        // setselectedKey(props.selectedKey)
        
        // setdisable({query:props.disabled.query, filter:props.disabled.filter, display:props.disabled.display, publicationBar:!props.publicationBar})
      }, [])
  return (
    <div className="flex-1 h-full">
        <Spin spinning={layoutProps.loading} className='w-full'>
          {query ||all ? (
            <div className='flex  '>
              <div className='flex-1 flex flex-col'>
                <div className="flex-1" >
                <ResourcesLayout
                    sponsors={sponsors}
                    publications={publications}
                    suffix={suffix}
                    allowSearch={allowSearch}
                    showApps={showApps}
                    groupBy={groupBy}
                    owner={owner}
                    all={all}
                    home={home}
                    webResources={webResources}
                    isLoaded={isLoaded}
                    partnerPublications={partnerPublications}
                    partnerId={partnerId}
                    />
                
        
                </div>
              </div>
              {/* <div className='w-[33%] flex flex-col'>
                <div className='flex-1'> */}
                  <Outlet/>
        
                {/* </div>
              </div> */}
            </div>

          ): (
            <div className='flex flex-col h-full w-full justify-center items-center  max-w-[300px] mx-auto'>
                <Icon name='SearchServer' className='w-[300px] h-[300px] opacity-10'/>
                <p className='text-center text-[20px] italic opacity-40'>Recherchez des pages web, des entreprises, des produits, des services, des formations,... sur le marché {engine}</p>
            </div>
          )}
      
      </Spin>
      

    </div>
          

       
    
  )
}
