import React, { useEffect, useState } from 'react'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router'
import { Drawer, Spin } from 'antd'
import { useAppContext } from '../../../context/appContext';
import PaginationComponent from '../../../components/shared/pagination';
import Header from '../components/header';



export default function ResourcesContainerLayout() {
    const { query, setQuery, pagination, setPaginate, totalHits, clearSearch, selectedPubType, setSelectedPubType, groupBy, selectedVolume, setSelectedVolume, setGroupBy } = useAppContext()
    const [loading, setloading] = useState(false);
    const navigate = useNavigate()
    const [all, setall] = useState(false);
    const location = useLocation()

    useEffect(() => {
      
      if(location.pathname.includes('partner')){
        setall(true)
      }else{
        setall(false)
      }
    }, [location.pathname]);
   
     
      

 

      useEffect(() => {
        setTimeout(() => {
          clearSearch()
          
        }, 1000);
      }, []);

   

    

     
  return (
   
   

            <>
            
               
              <Header
                filter={{
                  selectedPubType,
                  setSelectedPubType
                }}
                query={query}
                setQuery={setQuery}
                volume={{
                  setSelectedVolume,
                  selectedVolume
                }}
                display={{
                  setGroupBy,
                  groupBy,
                  displayMode: 'OneLine',
                  setDisplayMode:() => {}
                }}
                />
              
                <div className="flex-1 bg-sub-windows pl-[6px] flex ">
               
                  <div className='flex-1'>
                    <Spin spinning={loading}>
                     <Outlet/>

                    </Spin>

                  </div>

                </div>
                <div className="border-t border-main">
                   <div className="flex justify-between bar-h items-center px-5">
                    <div className="flex-1 flex items-center space-x-4">
                      
                     {totalHits.pubs ? (
                      <p>{totalHits.pubs} Publications</p>
                     ) : ''}
                     {totalHits.webresources ? (
                      <p className='flex'>{totalHits.webresources} Webpages</p>
                     ) : ''}
                     <div className="flex-1">

                      <PaginationComponent
                          disabled={false}
                          pagination={pagination}
                          totalHits={totalHits}    
                          hidePanel={false}
                          setPaginate={setPaginate}
                          allowPaginate={{
                            webresources: !groupBy.webresources,
                            pubs: !groupBy.paidPubs && !!selectedPubType.value,
                            partnerPubs: all,
                          }}
                      />
                     </div>

                    
                      
                    </div>
                   </div>

                </div>
            </> 

  )
}
