

// @ts-nocheck

import Typesense from "typesense"
import axios from "axios"
const TYPESENSE_CONFIG :any= {
  
    nodes : [
      {
          host: process.env.REACT_APP_TYPESENSE_HOST,
          port:process.env.REACT_APP_TYPESENSE_PORT,
          protocol:process.env.REACT_APP_TYPESENSE_PROTOCOL
      }
  ],
  apiKey: process.env.REACT_APP_TYPESENSE_KEY
}

export const search = new Typesense.Client(TYPESENSE_CONFIG)






export function searchLocations({q, id, extraFilter, limit, sort}: {q:string, id:string, extraFilter?:string, limit?:number, sort?:string}){
  return search.collections('locations').documents().search({
    'q': q,
    'query_by'  : 'title.en,title.fr, breadcrumbs.en, breadcrumbs.fr, description.en, description.fr',
    'sort_by':sort ? sort : 'title.en:asc',
    'filter_by': `status:enabled && roots:=${id} ${extraFilter ? `&& ${extraFilter}` : ''}`,
    'text_match_type': 'max_weight',
    'prioritize_token_position':true,
    'exclude_fields':'pinned_sources',
    'limit':limit ? limit : 100,
    query_by_weights: '50,50,10,10,20,20',
    "prioritize_num_matching_fields":false,
    "include_fields": "$organizations(*)"
    
 
  })
}


let cancelTokenPubs:any
export function searchPublications({q, volumeId,mode='roots', page, offset, type, groupBy, limit, sponsored, extraFilter, selectedVolume}: {q:string, volumeId:string, page?: number, offset?:number, type?:{type: 'groupe' | 'type', value:string}, mode?:'roots' | 'volume' , groupBy?:string, limit?:number, sponsored?:boolean, extraFilter?:string, selectedVolume?:string}){
  if (cancelTokenPubs) {
    cancelTokenPubs.cancel("Operation canceled due to new request.");
  }

  cancelTokenPubs = axios.CancelToken.source();
  return search.collections('publications_link').documents().search({
    'q': q,
    'query_by'  : 'url,title.en, title.fr,description.en, description.fr, keywords.en, keywords.fr',
    'filter_by': `status:published && ${mode === 'volume' ? `(volume:${volumeId} || volumes:=${volumeId})` : `roots:=${volumeId}`} ${(type?.value && type.type === 'type') ? `&& publicationType.id:${type.value}` : ''} ${(type?.value && type.type === 'groupe') ? `&& publicationGroupe.id:${type.value}` : ''} ${sponsored ? '&& sponsored:true' : ''} ${extraFilter ?? ''} ${selectedVolume ? `&& roots:=${selectedVolume}` : ''}`,
    'group_by' : groupBy?groupBy:'',
    limit: (limit && limit > 0) ? limit :15,
    page : page? page:1,
    offset: offset?offset:0,

    facet_by :'publicationType.id',
    'group_limit': 7,
    'prioritize_token_position':true,
    // "sort_by": groupBy ? 'created_at:desc' : 'created_at:desc',
    query_by_weights: '10,7,7,2,2,3,3',
    "prioritize_num_matching_fields":false,
    'prioritize_exact_match':false,
    'text_match_type': 'max_weight',
    num_typos: 1,
    'exclude_fields':'pinned_sources',
    snippet_threshold	: 500

  },{
    cancelToken: cancelTokenPubs.token,
  })

}
let cancelTokenWebresources:any
export function searchWebResources({q, volumeId, groupBy, groupLimit, websiteId, limit, offset, page, mode='roots', selectedVolume}: {q:string, volumeId:string, groupBy?:string, groupLimit?:number, websiteId?:string, limit?:number, page?:number, offset?:number,mode?:'roots' | 'volume' , selectedVolume?:string }){
  if (cancelTokenWebresources) {
    cancelTokenWebresources.cancel("Operation canceled due to new request.");
  }

  cancelTokenWebresources = axios.CancelToken.source();

  return search.collections('web_resources').documents().search({
    'q': q,
    'query_by'  : 'url,title.en, title.fr,description.en, description.fr, keywords.en, keywords.fr',
    'filter_by': `status:published && ${mode === 'volume' ? `(volume:${volumeId} || volumes:=${volumeId})` : `roots:=${volumeId}`} ${websiteId ? `&& main_website:${websiteId}` : ''} ${selectedVolume ? `&& roots:=${selectedVolume}` : ''}`,
    'group_by' : groupBy ? groupBy: '',
    limit: (limit && limit > 0) ? limit :15,
    page : page? page:1,
    offset: offset?offset:0,
    'group_limit': groupLimit ? groupLimit : 10,
    // 'text_match_type': 'max_score',
    'prioritize_token_position':true,
    "sort_by": 'created_at:desc',
    'exclude_fields':'pinned_sources',
    query_by_weights: '10,7,7,2,2,3,3',
    "prioritize_num_matching_fields":false,
    'text_match_type': 'max_weight',
    num_typos: 1,
    "include_fields": "$publications_link(*)",
    snippet_threshold	: 500
  
  },{
    cancelTokenWebresources: cancelTokenPubs.token,
  })

}





export function searchDatacenters({q,page, offset, limit, filter}: {q:string, page?: number, offset?:number, limit?:number, filter?:string }){
  return search.collections('domains').documents().search({
    'q': q,
    'query_by'  : 'title.en, title.fr',
    'filter_by': filter ? filter : `status:enabled`,
    limit:limit ? limit : 200,
    page : page? page:1,
    offset: offset?offset:0,
    'sort_by':'title.en:asc',
    'text_match_type': 'max_score',
    'prioritize_token_position':true

  })

}

export function searchLocalServers({q, datacenterId}: {q:string, datacenterId:string}){
  return search.collections('locations').documents().search({
    'q': q,
    'query_by'  : 'title.en',
    // 'sort_by':'depth:asc',
    'filter_by': `status:enabled && datacenterId:${datacenterId} && geoVolume:true`,
    'text_match_type': 'max_score',
    'prioritize_token_position':true
 
  })

}



export const getPopularCommunities = () => {
  return search.collections('datacenters').documents().search({
    'q': '*',
    'query_by'  : 'title.en',
    'sort_by':'title.en:asc',
    'filter_by': `status:!=disabled && popular:true`,
    'limit': 50,
    'exclude_fields':'pinned_sources'
 
  })
}


export const getPublication = ({id}:{id:string}) => {
  return search.collections('publications_link').documents(id).retrieve()
}
export const getWebPage = ({id}:{id:string}) => {
  return search.collections('web_resources').documents(id).retrieve()
}
export const getVolume = ({id}:{id:string}) => {
  return search.collections('locations').documents(id).retrieve()
}
export const getServer = ({ids, datacenterIds}: {ids:string[], datacenterIds:string[]}) => {

  return search.collections('locations').documents().search({
    'q': '*',
    'query_by'  : 'title.en, title.fr',
    'filter_by': `id:[${ids}] && cfs_type:localServers && datacenterId:${datacenterIds}`,
  

  })
}


