import React from 'react'
import { displayProps } from '../../../../types'
import DisplayDropdown from '../../../../components/shared/bars/TopBar/actionsBar/actions/resources/DisplayDropdown'
import { Dropdown } from 'antd'
import { ButtonLg } from '../../../../components/shared/Buttons/ButtonLg'

type Props = {
    display?:displayProps, 
    bar?: {
    barActivatedFields: { author:boolean, date: boolean}
    setBarActivatedFields: (data:{ author?:boolean, date?: boolean}) => void

  }}
export default function Display({ display, bar }: Props) {
  return (
    <Dropdown
        overlay={!display ? <></> : <DisplayDropdown setGroupBy={(value:boolean) => display?.setGroupBy({paidPubs:true})} groupBy={display.groupBy} displayMode={display.displayMode} setDisplayMode={display.setDisplayMode} barActivatedFields={bar?.barActivatedFields} setBarActivatedFields={bar?.setBarActivatedFields} /> }
        trigger={['click']}
        disabled={(!display)}
        
        >
                <button>
                    <ButtonLg
                        suffix={{
                            icon:{type:'icon', src: 'ChevronDownIcon' },
                            size: 'mini'
                        }}
                        buttonProps={{
                            disabled: true
                        }}
                        prefix={{
                            icon:{type:'icon', src: display?.groupBy ? 'GroupBy' : 'Degroup' },
                            size: 'sm'
                        }}
                        
                    ><>Display</></ButtonLg>

                </button>
                                                            
            
        </Dropdown> 
  )
}
