import { ArrowLeftIcon, XCircleIcon } from '@heroicons/react/solid'
import React from 'react'
import { displayProps, filterProps } from '../../../../types'
import { Search } from '../../../../components/shared/input/Search'
import { useNavigate } from 'react-router'
import Volumes from './Volumes'
import Filter from './Filter'
import Display from './Display'


type Props ={
    filter:filterProps,
    query:string,
    setQuery:any,
    volume:{
        setSelectedVolume: (data: {value:string, icon:string, title:string}) => void,
        selectedVolume: {value:string, icon:string, title:string},
    },
    display:displayProps
}
export default function Header({ filter, query, setQuery, volume, display }: Props) {
    const navigate = useNavigate()

    const onCancel = () => {
      setQuery('')
      volume.setSelectedVolume({value:'', icon:'', title:''})
      filter.setSelectedPubType({value:'', icon:'', type:'type', title:''})
    }
  return (
    <div className="bar-h flex items-center space-x-3 px-5">
             
                
    <button className='p-1.5 bg-hover-transparent rounded no-dragable disabled:fill-skin-muted' onClick={() => navigate(-1)} >
        <ArrowLeftIcon className='icon-sm'/>
    </button>
    <div className="flex-1 flex overflow-hidden border border-gray-300 rounded-full mx-2 max-w-[700px]">
      <div className="flex">
        <Filter
            
            {...filter}
            
        />
        <Volumes
            {...volume}
        />
      </div>
      <div className="relative flex-1">
        <Search
            placeholder='Search'
            value={query}
            onChange={e => setQuery(e.target.value)}
            className='bg-input rounded min-w-[250px] flex-1 '
        />
        <button className='absolute top-[8px] right-[8px] opacity-50 hover:opacity-100' onClick={onCancel}>
            <XCircleIcon className='icon-sm'/>
        </button>

      </div>

    </div>
      <Display
        display={display}
      />
  </div>
  )
}
