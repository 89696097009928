import { Dropdown } from 'antd'
import { useEffect, useState } from 'react'
import { filterProps, ResourcesActions } from '../../../../types';
import { useParams } from 'react-router';
import { getActiveGroupsWithChilds } from '../../../../utils/requests';
import TypesDropdown from '../../../../components/shared/bars/TopBar/actionsBar/actions/resources/TypesDropdown';
import { ButtonLg } from '../../../../components/shared/Buttons/ButtonLg';



type Props = filterProps
export default function Filter({selectedPubType, setSelectedPubType}: Props) {
    const [loading, setloading] = useState(false)
    const [publicationsTypes, setpublicationsTypes] = useState([]);
    // const [filterDropdown, setfilterDropdown] = useState(false)
    const params = useParams()
    
    useEffect(() => {
        (async() => {
            setloading(true)
            if(!publicationsTypes || publicationsTypes?.length === 0){
                console.log('ex')
                const data = await fetch(getActiveGroupsWithChilds).then(res => res.json())
                setpublicationsTypes(data.data)
            }
            setloading(false)
        })()
    }, []) 


  return (
           
                 
        <Dropdown 
        overlay={(!selectedPubType) ? <></> : <TypesDropdown selectedType={selectedPubType} selectAction={(node:any) => setSelectedPubType({ value: node.id, icon: node.iconUrl, type:'type' , title:node.name_plural.en})} typesList={publicationsTypes}/>} trigger={['click']} disabled={!!(loading || params.group)} 
        // onVisibleChange={visible => setfilterDropdown(visible)}
        >
            {/* <Spin spinning={loading}> */}
            <button className='w-[150px]'>
                <ButtonLg
                    suffix={{
                        icon:{type:'icon', src: selectedPubType?.value ? 'XCircleIcon' : 'ChevronDownIcon'},
                        size: 'mini',
                        onClick:() => selectedPubType.value ? setSelectedPubType({value:'', icon: '', type:'type', title: ''}) : {}
                    }}
                    prefix={{
                        icon:selectedPubType?.value ? {type:'image', src: selectedPubType?.icon} : {type:'icon', src: 'FilterIcon'},
                        size: 'sm'
                    }}
                    buttonProps={{
                        disabled:!!(loading || !selectedPubType || params.group)

                    }}
                ><>{selectedPubType?.value? selectedPubType.title :'Type'}</></ButtonLg>

            </button>
            

        </Dropdown>
        
            

          
            

              


  )
}
